import style from "./index.module.css";
import Navbar from "./navbar";
import Footer from "./footer";


function RefundPolicy() {
    const backquote = "'"; 
    return (
        <div>
            <div className={style.logoheader}>
            <Navbar/>
            </div>
            <div className={`${style.privacypolicy}`}>
                <div className={`${style.subhead} ${style.backshade} ${style.padding10}`}>
                <h3>Refund and Cancellation Policy</h3>
                Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we refund back the money, no questions asked.
                <br/><br/>
                <h3>Cancellation Policy</h3>
                On cancellation of subscription, you will not be charged for the next monthly cycle. If you cancel the subscription after the payment you will not be refunded, however you do enjoy the VIP services till the next billing cycle.

                If you order any physical goods and would like to cancel the order, you will refunded the amount paid to RealmOfRich platform after due process and after we receive confirmation of actual product has been returned.
                <br/><br/>
                <br/><br/>
                <h3>Ungovernable Factors</h3>
                The result of a particular process/service cannot be guaranteed as the same involves dependency on Governing Authorities related to the said service. This further implies that rejections/ approvals /responses by Govt Authorities or technical issues with Government sites including Government backlogs etc. are not within the purview of RealmOfRich platform. These may lead to unexpected delays before completion of the process and factors like these are outside our control hence not eligible for refund.
                    
                
            </div>
            </div>
            <Footer />
        </div>
    );
}

export default RefundPolicy;