import React from 'react';
import style from "./realm.module.css";
import guildcoins from "./IMG_17875.png";import castle from "../../assets/icons/004-castle-2.png";import location from "../../assets/icons/001-location.png";
import bigcastle from "../../assets/icons/002-castle.png";
import queen from "../../assets/icons/002-queen.png";import rightarrow from "../../assets/icons/003-right-arrow.png";import biryani from "../../assets/icons/003-biryani.png";
import king from "../../assets/icons/001-king.png";import handshake from "../../assets/icons/002-handshake.png";
import flag from "../../assets/icons/001-flag.png";import bonus from "../../assets/icons/002-bonus.png";
import trophy from "../../assets/icons/004-trophy.png";import plus from "./005-plus 1.png";

function Guildcoins() {
  return (
    <div className={style.guildcoinscontainer}>
        <div className={style.guildcoinstitle}>GuildCoins</div>
        <div className={style.addguildcoins}>
            <div className={style.addfunds}>
                Add funds
            </div>
            <div className={style.fundstatus}>
                5000
                <img className={style.coin} src = {guildcoins} />
            </div>
        </div>
        <div className={style.guildcoinstagline}>
            Here are the things money can't buy
        </div>
        <div className={style.guildcoinsservices}>
                <div className={style.guildcoinsservicestagline}>Get what makes you happy</div>
            <div className={style.serviceicons} id={style.school}></div>
            <div className={style.serviceicons} id={style.nurse}></div>
            <div className={style.serviceicons} id={style.emergency}></div>
            <div className={style.serviceicons} id={style.hospital}></div>
            <div className={style.serviceicons} id={style.petservice}></div>

            <div className={style.serviceicons} id={style.dropservice}></div>
            <div className={style.serviceicons} id={style.teacher}></div>
            <div className={style.serviceicons} id={style.maid}></div>
            <div className={style.serviceicons} id={style.transport}></div>
            <div className={style.serviceicons} id={style.babycare}></div>

            <div className={style.serviceicons} id={style.marketresearch}></div>
            <div className={style.serviceicons} id={style.makeup}></div>
            <div className={style.serviceicons} id={style.secretary}></div>
            <div className={style.serviceicons} id={style.salon}></div>
            <div className={style.serviceicons} id={style.dining}></div>
            <div className={style.serviceicons} id={style.extras}>Extras</div>
        </div>
        <div className={style.background1}>
            <div className={style.homeguild}>
                <div className={style.homeguildaddress}>
                    <div className={style.guildtown}>
                        <img className={style.guildcoinsgeneralicon} src = {castle} />
                        <div className={style.guildtowntext}>Moubhandar</div>
                    </div>
                    <div className={style.addresstown}>
                        <img className={style.guildcoinsgeneralicon} src = {location} />
                        <div className={style.guildtowntext}>NF - 9/7</div>
                    </div>
                    
                </div>
                <div className={style.welcomeguild}>
                    <div className={style.welcometoguildtagline}>
                        Welcome to the guild
                    </div>
                    <img className={style.bigcastle} src = {bigcastle} />
                </div>
            </div>
            <div className={style.whatscooking}>
                <div className={style.welcometoguildtagline}>
                Whats cooking today?
                </div>
            </div>
            <div className={style.foodordering}>
                    <div>
                        <div className={style.food} id={style.food1}></div>
                        Sandwich
                    </div>
                    <div>
                        <div className={style.food} id={style.food2}></div>
                        Sandwich
                    </div>
                    <div>
                        <div className={style.food} id={style.food3}></div>
                        Sandwich
                    </div>

                    <div>
                        <div className={style.food} id={style.food4}></div>
                        Sandwich
                    </div>
                    <div>
                        <div className={style.food} id={style.food5}></div>
                        Sandwich
                    </div>
                    <div>
                        <div className={style.food} id={style.food6}></div>
                        Sandwich
                    </div>
            </div>
        </div>
        <div className={style.background2}>
            <div className={style.earnfirstguildcoins}>
                <div className={style.earnfirstguildcoinstext}>
                    Earning guildcoins is as easy as sharing
                    food with someone
                </div>
                <div className={style.earnfirstguildcoinsblock}>
                    <div className={style.earnfirstguildcoinsblocktext}>
                        Make food for someone
                    </div>
                    <div className={style.earnfirstguildcoinsexplanation}>
                        <img className={style.queenicon} src={queen} />
                        <img className={style.rightarrow} src={rightarrow} />
                        <img className={style.biryani} src={biryani} />
                        <img className={style.rightarrow} src={rightarrow} />
                        <img className={style.kingicon} src={king} />
                    </div>
                    <div className={style.earnfirstguildcoinsshare}>
                        Begin
                    </div>
                </div>
            </div>

            <div className={style.priviledgedrequests}>
                <div className={style.priviledgedrequestshead}>
                    Priviledged requests
                </div>
            </div>
        </div>
        <div className={style.guildssalute}>
            <div className={style.guildsalutetext}>Guilds salute</div>
            <div className={style.guildsaluteexplanation}>
                <img className={style.pinkcastle} src={bigcastle} />
                <div className={style.handshakecontainer}>
                <img className={style.handshake} src={handshake} />
                </div>
                
                <img className={style.yellowcastle} src={bigcastle} />
            </div>
            <div class={style.contentContainer}>
            <div class={style.content}>
              <div class={style.content__container}>
                <ul class={style.content__container__list}>
                  <li class={style.content__container__list__item}>Compete</li>
                  <li class={style.content__container__list__item}>Align</li>
                  <li class={style.content__container__list__item}>Find DeepBlueSea</li>
                  <li class={style.content__container__list__item}>Cooperate</li>
                </ul>
              </div>
            </div>
          </div>
            <div className={style.guildcoinstabs}>
                <div className={style.guildcoinstab1}>Overall</div>
                <div className={style.guildcoinstab2}>Organization</div>
                <div className={style.guildcoinstab3}>Investment</div>
            </div>
            <div className={style.guildcoinsscrollranks}>
                <div className={style.guildrank5}>
                    <img className={style.violetcastle} src={bigcastle} />
                </div>
                <div className={style.guildrank3}>
                    <img className={style.pinkcastle} src={bigcastle} />
                </div>
                <div className={style.guildrank1}>
                    <img className={style.yellowcastle} src={bigcastle} />
                </div>
                <div className={style.guildrank2}>
                    <img className={style.violetcastle} src={bigcastle} />
                </div>
                <div className={style.guildrank4}>
                    <img className={style.pinkcastle} src={bigcastle} />
                </div>
            </div>
            <div className={style.guildcoinsruler}>
            <div className={style.rulercontainer}>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.boldmarker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.boldmarker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.boldmarker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.boldmarker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.boldmarker}><div>69</div></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.boldmarker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.boldmarker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.marker}></div>
                <div className={style.boldmarker}></div>
            </div>
            </div>
        </div>
        <div className={style.guildcoinsotherranks}>
            <div className={style.achievementModalgrid}>
                <div className={style.leaderboardslot}>
                    <div className={style.numberedList}>
                        <div className={style.number}>1</div>
                        <div className={style.leaderprofilepic}><img src = { "../icons/profilepic-min.png"} /></div>
                    </div>
                    <div className={style.milestone}>
                        <img className='' src={flag} />
                    </div>
                    <div className={style.bonus}>
                        <img className='' src={bonus} />
                    </div>
                    <div className={style.trophies}>
                        <div className={style.trophyicon}>
                            <img className='' src={trophy} />
                        </div>
                        <div className={style.trophynumber}>
                            2256
                        </div>
                    </div>
                </div>

                <div className={style.leaderboardslot}>
                    <div className={style.numberedList}>
                        <div className={style.number}>1</div>
                        <div className={style.leaderprofilepic}><img src = {"../icons/profilepic-min.png"} /></div>
                    </div>
                    <div className={style.milestone}>
                        <img className='' src={flag} />
                    </div>
                    <div className={style.bonus}>
                        <img className='' src={bonus} />
                    </div>
                    <div className={style.trophies}>
                        <div className={style.trophyicon}>
                            <img className='' src={trophy} />
                        </div>
                        <div className={style.trophynumber}>
                            2256
                        </div>
                    </div>
                </div>

                <div className={style.leaderboardslot}>
                    <div className={style.numberedList}>
                        <div className={style.number}>1</div>
                        <div className={style.leaderprofilepic}><img src = {"../icons/profilepic-min.png"} /></div>
                    </div>
                    <div className={style.milestone}>
                        <img className='' src={flag} />
                    </div>
                    <div className={style.bonus}>
                        <img className='' src={bonus} />
                    </div>
                    <div className={style.trophies}>
                        <div className={style.trophyicon}>
                            <img className='' src={trophy} />
                        </div>
                        <div className={style.trophynumber}>
                            2256
                        </div>
                    </div>
                </div>

                <div className={style.leaderboardslot}>
                    <div className={style.numberedList}>
                        <div className={style.number}>1</div>
                        <div className={style.leaderprofilepic}><img src = {"../icons/profilepic-min.png"} /></div>
                    </div>
                    <div className={style.milestone}>
                        <img className='' src={flag} />
                    </div>
                    <div className={style.bonus}>
                        <img className='' src={bonus} />
                    </div>
                    <div className={style.trophies}>
                        <div className={style.trophyicon}>
                            <img className='' src={trophy} />
                        </div>
                        <div className={style.trophynumber}>
                            2256
                        </div>
                    </div>
                </div>

                <div className={style.leaderboardslot}>
                    <div className={style.numberedList}>
                        <div className={style.number}>1</div>
                        <div className={style.leaderprofilepic}><img src = {"../icons/profilepic-min.png"} /></div>
                    </div>
                    <div className={style.milestone}>
                        <img className='' src={flag} />
                    </div>
                    <div className={style.bonus}>
                        <img className='' src={bonus} />
                    </div>
                    <div className={style.trophies}>
                        <div className={style.trophyicon}>
                            <img className='' src={trophy} />
                        </div>
                        <div className={style.trophynumber}>
                            2256
                        </div>
                    </div>
                </div>

                <div className={style.leaderboardslot}>
                    <div className={style.numberedList}>
                        <div className={style.number}>1</div>
                        <div className={style.leaderprofilepic}><img src = {"../icons/profilepic-min.png"} /></div>
                    </div>
                    <div className={style.milestone}>
                        <img className='' src={flag} />
                    </div>
                    <div className={style.bonus}>
                        <img className='' src={bonus} />
                    </div>
                    <div className={style.trophies}>
                        <div className={style.trophyicon}>
                            <img className='' src={trophy} />
                        </div>
                        <div className={style.trophynumber}>
                            2256
                        </div>
                    </div>
                </div>

                <div className={style.leaderboardslot}>
                    <div className={style.numberedList}>
                        <div className={style.number}>1</div>
                        <div className={style.leaderprofilepic}><img src = {"../icons/profilepic-min.png"} /></div>
                    </div>
                    <div className={style.milestone}>
                        <img className='' src={flag} />
                    </div>
                    <div className={style.bonus}>
                        <img className='' src={bonus} />
                    </div>
                    <div className={style.trophies}>
                        <div className={style.trophyicon}>
                            <img className='' src={trophy} />
                        </div>
                        <div className={style.trophynumber}>
                            2256
                        </div>
                    </div>
                </div>

                <div className={style.leaderboardslot}>
                    <div className={style.numberedList}>
                        <div className={style.number}>1</div>
                        <div className={style.leaderprofilepic}><img src = {"../icons/profilepic-min.png"} /></div>
                    </div>
                    <div className={style.milestone}>
                        <img className='' src={flag} />
                    </div>
                    <div className={style.bonus}>
                        <img className='' src={bonus} />
                    </div>
                    <div className={style.trophies}>
                        <div className={style.trophyicon}>
                            <img className='' src={trophy} />
                        </div>
                        <div className={style.trophynumber}>
                            2256
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
        <div className={style.createguild}>

            Create Guild
        </div>
        <div className={style.guildcoinsmicrobusiness}>
            <div className={style.guildcoinsmicrobusinessheader}>
                <div className={style.microbusinessheadline}> Looking for funds, for business startup</div>
                <div className={style.microbusinesssecondline}> Microbusinesses looking for funds</div>
            </div>
            <div className={style.guildcoinsmicrobusinesslink}>
                <div className={style.guildcoinsmicrobusinesslinkimg}></div>
                <div className={style.guildcoinsmicrobusinesslinkimg}></div>
            </div>
            <div className={style.guildcoinsmicrobusinesscontent}>
                <div className={style.guildcoinsmicrobusinesscontentgrid}>
                    <div className={style.guildcoinsmicrobusinesscontentslots}>
                        <div className={style.businessheading}>Opening a bakery Factory</div>
                        <div className={style.businessaskingprice}>
                            <div className={style.businessaskingpriceval}>
                                200
                            </div>
                            <img src = {guildcoins} />
                        </div>
                        <div className={style.businesstokenofappreciation}>Token of appreciation</div>
                        <div className={style.businesstokenofappreciationvalue}>Lifetime bread coupon</div>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.superdonationcontainer}>
            <div className={style.superdonation}>
                <div className={style.superdonationheader}>
                    <div className={style.super}></div>
                    <div className={style.donations}></div>

                    <div className={style.NGOcircles}>
                    <div className={style.superdonationNGO}>
                        NGO1
                    </div>
                    <div className={style.superdonationNGO}>
                        NGO1
                    </div>
                    <div className={style.superdonationNGO}>
                        NGO1
                    </div>
                    <div className={style.superdonationNGO}>
                        NGO1
                    </div>
                    <div className={style.superdonationNGO}>
                        NGO1
                    </div>
                    <div className={style.superdonationNGO}>
                        NGO1
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className={style.buildproject}>
            Build Project

        </div>
        <div className={style.earnguildcoinscontainer}>
            <div className={style.earnguildcoins}>
                <div className={style.earnguildcoinsheader}>
                    <div className={style.earnguildcoinslocation}>
                        <img className={style.protectionIcon} src = {location} />
                        <div className={style.shieldTime}>
                        2 Days
                        </div>
                        
                        <img className={style.addProtectionIcon} src = {plus} />
                    </div>
                    
                    <div className={style.earnguildcoinsheading}>
                        Earn guildcoins by completing Tasks
                    </div>
                    <div className={style.earnguildcoinstodo}></div>
                </div>
                    
            </div>
        </div>
        <div className={style.verifiedbadge}>
            <div className={style.verifiedbadgeheader}>
                <div className={style.verifiedbadgeheadline}>Get verified badge</div>
                <div className={style.verifiedbadgesecondline}>Deals offered and served - verified badges</div>
            </div>
            <div className={style.verifiedbadgecontent}>
                
            </div>
        </div>
    </div>

  )
}

export default Guildcoins;