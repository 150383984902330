import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import style from "./index.module.css";
import UpgradeVIP from '../components/upgradeVIP';


function Navbar() {
    const [pricingscreen, setpricingscreen] = useState(false);
    return (
        <>
        <div className={pricingscreen? '' : `${style.display_none}`}>
            <UpgradeVIP toggleopenclose = {()=>setpricingscreen(!pricingscreen)} frontpage= {pricingscreen}/>
        </div>
        <nav className={style.menu}>
            <div className = {style.logo}>
                <Link to = "/">
                    <img src = "774655QZp2g5GY.png" alt = "Logo of Realm of Rich"></img>
                </Link>
            </div>
            
            <div className = {style.navitemcompany}><Link to = "/companyinfo">Company</Link></div>
            <div className = {style.navitempricing} onClick={()=>setpricingscreen(!pricingscreen)}>Pricing</div>
            {/*<a href="#popup1" className={`${style.headerbutton} ${style.btn}`} id ={style.loginbutton}>Log In</a>*/}
            <a href="#popup1"><div className = {style.navitemlogin}>Log in</div></a>
            
            <div id="popup1" class={style.popup}>
            <a href="#popupclose" class={style.close}>&times;</a>
            <h2>Login to enjoy the magic</h2>

            <div id="auth-options"></div>
            <div id="loader">Loading...</div>
            </div>
            <a href="#popupclose" class={style.closepopup}></a>
            
        </nav>
        </>
    );
}

export default Navbar;