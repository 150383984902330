import React from 'react';
import style from './pepper.module.css';

function Blackhole() {
  return (
    <div className={style.blackhole}>
      <div class={style.boxFixed}>
        <div class={style.box}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export function Blackhole1() {
  return (
    <>
    {/*
    <div className={style.blackhole}>
      <div class={style.boxFixed}>
      <ol id={style.rule}>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
        <li className={style.rulerlist}></li>
      </ol>
      </div>
    </div>
    */}
    <div className={style.blackhole}>
      <div class={style.boxFixed}>
        <div className={style.rulercontainer}>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}><div>69</div></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.marker}></div>
        <div className={style.boldmarker}></div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Blackhole;