import React from 'react';
import style from "./realm.module.css";
import deposit from "../../assets/icons/004-deposit.png";import moneybag from "../../assets/icons/005-money-bag.png";import bank from "../../assets/icons/003-bank.png";

function Bank() {
  return (
    <div className={style.guildcoinscontainer}>
        <div className={style.guildcoinstitle} id={style.guildcoinstitle}>I am coming for your gold</div>
        
          {<div className={style.bankheading}>
              Investing
          </div>}

        <div className={style.homeguild} id={style.bankaddress}>
                <div className={style.homeguildaddress}>
                    <div className={style.guildtown}>
                        <img className={style.guildcoinsgeneralicon} src={deposit}/>
                        <div className={style.guildtowntext}>Balance & History</div>
                    </div>
                    <div className={style.addresstown}>
                        <img className={style.guildcoinsgeneralicon} src={moneybag}/>
                        <div className={style.guildtowntext}>SBI xxxxxxxxx6791</div>
                    </div>
                    
                </div>
                <div className={style.welcomeguild}>
                    <div className={style.welcometoguildtagline} id={style.bankwelcometoguildtagline}>
                        Welcome to the Investing
                    </div>
                    <img className={style.bigcastle} src={bank}/>
                </div>
            </div>

          <div className={style.background1} id={style.bankbackground1}>
          <div className={style.upimoneytransfer}>
            <div className={style.upimoneytransfertitle}>UPI money transfer</div>
            <div className={style.upimoneytransfercontent}>
              <div className={style.scanpaycontainer}>
                <div className={style.scanpay}>
                </div>
                <div className={style.upimoneytransfercontenttags}>Scan & Pay</div>
              </div>
              <div className={style.tomobilecontainer}>
                <div className={style.tomobile}>
                </div>
                <div className={style.upimoneytransfercontenttags}>To Mobile</div>
              </div>
              <div className={style.tobankaccountcontainer}>
                <div className={style.tobankaccount}>
                </div>
                <div className={style.upimoneytransfercontenttags}>To Bank account</div>
              </div>
            </div>
          </div>

          <div className={style.bankfunctions}>
            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.recharge}></div>
              <div className={style.upimoneytransfercontenttags}>Recharge</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.creditcard}></div>
              <div className={style.upimoneytransfercontenttags}>Credit card</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.terminsurance}></div>
              <div className={style.upimoneytransfercontenttags}>Term Insurance</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.healthinsurance}></div>
              <div className={style.upimoneytransfercontenttags}>Health Insurance</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.investmentplans}></div>
              <div className={style.upimoneytransfercontenttags}>Investment plans</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.carinsurance}></div>
              <div className={style.upimoneytransfercontenttags}>Car Insurance</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.bikeinsurance}></div>
              <div className={style.upimoneytransfercontenttags}>Bike Insurance</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.familyinsurance}></div>
              <div className={style.upimoneytransfercontenttags}>family Insurance</div>
            </div>
            
            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.guaranteedreturn}></div>
              <div className={style.upimoneytransfercontenttags}>Guaranteed return plans</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.childsavings}></div>
              <div className={style.upimoneytransfercontenttags}>Child savings plans</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.retirementplans}></div>
              <div className={style.upimoneytransfercontenttags}>Retirement plans</div>
            </div>

            <div className={style.bankfunctionscontentcontainer}>
              <div className={style.bankfunctionscontent} id={style.retirementhome}></div>
              <div className={style.upimoneytransfercontenttags}>Retirement Home</div>
            </div>

          </div>
          </div>

    </div>
  )
}

export default Bank;