import style from "./index.module.css";
import Navbar from "./navbar";
import Footer from "./footer";


function Contactus() {
    return (
        <div>
            <div className={style.logoheader}>
            <Navbar/>
            </div>
            <div className={`${style.privacypolicy}`}>
                <div className={`${style.subhead} ${style.backshade}`}>
                    <div className={`${style.columnGrid}`}>
                    <h4>How do you want to contact us</h4>
                        <div className={`${style.one}`}>
                            <div className={style.onecontent}>
                                By Phone
                            </div>
                        </div>
                        <div className={`${style.one}`}>
                            <div className={style.onecontent}>
                                Log a request
                            </div>
                        </div>
                        <div className={`${style.one}`}>
                            <div className={style.onecontent}>
                                Log a complaint
                            </div>
                        </div>
                        <div className={`${style.one}`}>
                            <div className={style.onecontent}>
                                Email
                            </div>
                        </div>
                        <div className={`${style.one}`}>
                            <div className={style.onecontent}>
                                Chat
                            </div>
                        </div>
                    </div>
                </div>
                    
                
            </div>
            <Footer />
        </div>
    );
}

export default Contactus;