import style from "./index.module.css";
function Divider() {
    return (
    <div className={style.divider}>

    </div>
    
    );
}

export default Divider;