import style from "./index.module.css";
import Navbar from "./navbar";
import Footer from "./footer";


function Faq() {
    return (
        <div>
            <div className={style.logoheader}>
            <Navbar/>
            </div>
            <div className={`${style.privacypolicy}`}>
                <h2>Frequently Asked Questions </h2>
                <div className={`${style.subhead} ${style.backshade}`}>
                    <div className={`${style.threeGrid}`}>
                        <div className={`${style.one}`}>
                            <div className={style.oneItem}><h4>Managing your Account</h4></div>
                            <div className={style.oneItem}>Managing your Account</div>
                            <div className={style.oneItem}>About Suspended Accounts</div>
                            <div className={style.oneItem}>Help with locked or limited accounts</div>
                            <div className={style.oneItem}>Multiple devices running a single account</div>
                            <div className={style.oneItem}>Verified Account</div>
                            <div className={style.oneItem}>How to add phone number or email address</div>
                            <div className={style.oneItem}>How to change your phone number or email address</div>
                        </div>
                        <div className={`${style.two}`}>
                            <div className={style.oneItem}><h4>Managing your Payments</h4></div>
                            <div className={style.oneItem}>How to cancel your subscription</div>
                            <div className={style.oneItem}>Refund policy for subscription</div>
                            <div className={style.oneItem}>Refund policy for physical products</div>
                            <div className={style.oneItem}>Pricing plans for RealmOfRich</div>
                        </div>
                        <div className={`${style.three}`}>
                            <div className={style.oneItem}><h4>Using RealmOfRich</h4></div>
                            <div className={style.oneItem}>Who can use RealmOfRich</div>
                            
                        </div>
                    </div>
                </div>
                    
                
            </div>
            <Footer />
        </div>
    );
}

export default Faq;